import OrderService from '../../services/userOrder';
import {
    cancelSingleOrderError, cancelSingleOrderRequest, cancelSingleOrderSuccess, createOrderError,
    createOrderRequest, createOrderSuccess, getOrderHistoryError, getOrderHistoryRequest, getOrderHistorySuccess,
    getSingleOrderError, getSingleOrderRequest, getSingleOrderSuccess, invoiceError, invoiceRequest, invoiceSuccess,
    getOrderTrackRequest,
    getOrderTrackSuccess,
    getOrderTrackError,
} from '../../slices/orderSlice';

// create order
export const generateOrder = (payload) => {
    return (dispatch) => {
        dispatch(createOrderRequest());
        OrderService.createOrder(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(createOrderSuccess({ data, message }))
                } else {
                    dispatch(createOrderError(error));
                }
            }).catch((error) => {
                dispatch(createOrderError(error))
            });
    }
}

// get order history
export const getOrderData = (payload) => {
    return (dispatch) => {
        dispatch(getOrderHistoryRequest());
        OrderService.getOrderHistory(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(getOrderHistorySuccess({ data, message }))
                } else {
                    dispatch(getOrderHistoryError(error));
                }
            }).catch((error) => {
                dispatch(getOrderHistoryError(error));
            })
    }
}

// get single order
export const getSingleOrderData = (payload) => {
    return (dispatch) => {
        dispatch(getSingleOrderRequest());
        OrderService.singleOrder(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(getSingleOrderSuccess({ data, message }))
                } else {
                    dispatch(getSingleOrderError(error));
                }
            }).catch((error) => {
                dispatch(getSingleOrderError(error));
            })
    }
}

// get single order
export const getSingleOrderProducts = (payload) => {
    return (dispatch) => {
        dispatch(getSingleOrderRequest());
        OrderService.singleOrder(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(getSingleOrderSuccess({ data, message }))
                } else {
                    dispatch(getSingleOrderError(error));
                }
            }).catch((error) => {
                dispatch(getSingleOrderError(error));
            })
    }
}

//cansle single order
export const cancelSingleOrder = (payload) => {
    return (dispatch) => {
        dispatch(cancelSingleOrderRequest());
        OrderService.cancelsingleOrder(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(cancelSingleOrderSuccess({ data, message }))
                } else {
                    dispatch(cancelSingleOrderError(error));
                }
            }).catch((error) => {
                dispatch(cancelSingleOrderError(error));
            })
    }
}

//download invoice
export const downloadInvo = (payload) => {
    return (dispatch) => {
        dispatch(invoiceRequest());
        OrderService.invoice(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(invoiceSuccess({ data, message }))
                } else {
                    dispatch(invoiceError(error));
                }
            }).catch((error) => {
                dispatch(invoiceError(error));
            })
    }
}

export const getOrderTracking = (payload) => {
    return (dispatch) => {
        dispatch(getOrderTrackRequest());
        OrderService.orderTracking(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(getOrderTrackSuccess({ data, message }))
                } else {
                    dispatch(getOrderTrackError(error));
                }
            }).catch((error) => {
                dispatch(getOrderTrackError(error))
            });
    }
}
