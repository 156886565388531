import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { getCartData, removeCartItem, updateCart } from '../../middlewares/cart/cart';
import LocalstorageService from '../../helpers/localstorage-services';
import { toast } from 'react-toastify';
import { resetDeleteCartList, resetUpdateCart } from '../../slices/cart';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from "yup";

const CartDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = LocalstorageService.getLoggedInUserDetails();
    const {
        cart,
        cartError,
        cartLoading,
        cartMessage,
        cartRemoveError,
        cartUpdateMessage,
        cartUpdateError,
        cartRemoveLoading,
        cartRemoveMessage,
    } = useSelector((state) => state.cart);

    const formik = useFormik({
        initialValues: {
            isChecked: false,
        },
        validationSchema: Yup.object({
            isChecked: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
        }),
        onSubmit: (values) => {
            navigate('/checkout/shipping');
        },
    });

    const handleCheckboxChange = (event) => {
        formik.setFieldValue('isChecked', event.target.checked);
    };

    const handleCheckout = () => {
        formik.submitForm();
    };
    const handleRemoveCartItem = (id) => {
        const bodyFormData = new FormData();
        bodyFormData.append("id", id);
        dispatch(removeCartItem(bodyFormData));
    };
    useEffect(() => {
        if (cartUpdateMessage) {
            const bodyFormData = new FormData();
            bodyFormData.append("user_id", user.id);
            dispatch(getCartData(bodyFormData));

            const updateToastId = toast.success(cartUpdateMessage);
            setTimeout(() => {
                toast.dismiss(updateToastId);
            }, 500);

            dispatch(resetUpdateCart());
        }
        if (cartUpdateError) {
            const errorToastId = toast.error(cartUpdateError);
            setTimeout(() => {
                toast.dismiss(errorToastId);
            }, 500);
            dispatch(resetUpdateCart());
        }
        if (cartRemoveMessage || cartRemoveError) {
            if (user) {
                const bodyFormData = new FormData();
                bodyFormData.append("user_id", user.id);
                dispatch(getCartData(bodyFormData));
            }
        }
        if (cartRemoveError) {
            const removeErrorToastId = toast.error(cartRemoveError);
            setTimeout(() => {
                toast.dismiss(removeErrorToastId);
            }, 500);

            dispatch(resetDeleteCartList());
        }
    }, [dispatch, cartUpdateMessage, cartRemoveMessage, cartRemoveError, cartUpdateError]);

    useEffect(() => {
        if (user) {
            const bodyFormData = new FormData();
            bodyFormData.append("user_id", user.id);
            dispatch(getCartData(bodyFormData));
        }
    }, [dispatch]);

    const handleQuantityChange = (id, quantityChange) => {
        const updatedCart = cart?.cart_item?.map((item) => {
            if (item.id === id) {
                if (item.quantity >= 10) {
                    if (quantityChange === -1) {
                        const newQuantity = Math.max(item.quantity + quantityChange, 1);
                        return { ...item, quantity: newQuantity };
                    }
                } else {
                    const newQuantity = Math.max(item.quantity + quantityChange, 1);
                    return { ...item, quantity: newQuantity };
                }
            }
            return item;
        });
        const bodyFormData = new FormData();
        bodyFormData.append("id", id);
        bodyFormData.append("quantity", updatedCart.find((item) => item?.id === id).quantity);
        dispatch(updateCart(bodyFormData));
    };

    return (
        <section className="flat-spacing-11">
            <div className="container">
                <div className="tf-cart-countdown">
                    <div className="title-left">
                        <svg width={20} height={30} viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_197_107)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.6124 30C14.1399 27.741 14.2806 25.3011 13.0346 22.6804C12.6881 24.0864 12.0543 24.9397 11.1331 25.2405C11.9885 22.7974 11.2737 20.1579 8.98848 17.322C8.93916 20.2509 8.2353 22.3845 6.87685 23.7228C5.00545 25.5646 5.02746 27.6432 6.94285 29.9586C-1.00824 25.7274 -2.12999 20.0523 3.57745 12.9338C3.93145 14.6535 4.78926 15.7093 6.15102 16.1011C4.66668 9.80233 6.22842 4.43526 10.8362 0C10.8645 9.84282 13.8827 10.7177 17.699 15.1112C21.8192 20.3839 19.398 26.4685 12.6124 30Z" fill="url(#paint0_linear_197_107)" />
                                <path d="M10.68 24.7702C10.6598 24.7215 10.6194 24.6758 10.5618 24.6365C10.5042 24.5972 10.4308 24.5653 10.347 24.5432C10.2203 24.516 10.0855 24.5014 9.94904 24.5C9.85412 24.4995 9.75997 24.5093 9.67235 24.5288C9.58474 24.5483 9.5055 24.5772 9.43944 24.6136C9.30827 24.6917 9.21671 24.7865 9.17346 24.8889C9.05748 25.1415 8.96068 25.6938 8.8766 26.5299C8.79293 27.3042 8.75072 27.9812 8.75 28.561C8.75 28.7409 8.75 28.8845 8.76703 28.9895C8.77236 29.0423 8.77873 29.0872 8.78832 29.1241C8.79477 29.1595 8.81055 29.1943 8.83515 29.2274C8.90161 29.3124 9.01665 29.3841 9.16387 29.4323C9.31772 29.4788 9.49251 29.502 9.66926 29.4993C9.89078 29.5058 10.1085 29.4668 10.2778 29.3903C10.353 29.3491 10.4114 29.3 10.4493 29.2462C10.4872 29.1924 10.5038 29.1349 10.498 29.0775C10.4876 28.9426 10.4085 28.8117 10.2693 28.6989C10.2437 28.2241 10.2697 27.749 10.347 27.2758C10.498 26.4508 10.6232 25.8346 10.7225 25.427V25.4202C10.7406 25.3067 10.7491 25.2073 10.7491 25.1137C10.755 24.9989 10.7313 24.8844 10.6789 24.7731M10.1257 24.8083L10.1374 24.8122C10.1346 24.8118 10.1316 24.8118 10.1288 24.8122" fill="#3F59A3" />
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_197_107" x1="9.85969" y1={0} x2="9.85969" y2={30} gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#00C53C" />
                                    <stop offset="0.73" stopColor="#FF8C00" />
                                </linearGradient>
                                <clipPath id="clip0_197_107">
                                    <rect width={20} height={30} fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <h6>These items are in limited supply, buy now before they're gone! </h6>
                    </div>
                </div>
                {cart?.cart_item?.length > 0 ? (
                    <div className="row justify-content-between ">
                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12">
                            <div className="tf-page-cart-item w-100 mb-3">
                                <form>
                                    <table className="tf-table-page-cart">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cart?.cart_item?.map((elem, i) => (
                                                <tr className="tf-cart-item file-delete" key={i}>
                                                    <td className="tf-cart-item_product">
                                                        <div className='d-flex align-items-center card-img-box'>
                                                            <Link to={`/${elem?.item_category_slug}/product-details/${elem?.product_id}`} className="img-box">
                                                                <div className="table-product-img-box">
                                                                    <img src={elem?.photos[0]} alt="img-product" />
                                                                </div>
                                                            </Link>
                                                            <div className="cart-info">
                                                                <div className="cart-info-detail">
                                                                    <Link to={`/${elem?.item_category_slug}/product-details/${elem?.product_id}`} className="cart-title link">{elem?.product_name}</Link>
                                                                </div>
                                                                {/* <div className="cart-meta-variant">White / M</div> */}
                                                                <span className="remove-cart link remove text-danger" onClick={() => handleRemoveCartItem(elem?.id)}>Remove</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="tf-cart-item_price" cart-data-title="Price">
                                                        <div className="cart-price">₹{elem?.indiazona_price}</div>
                                                    </td>
                                                    <td className="tf-cart-item_quantity" cart-data-title="Quantity">
                                                        <div className="cart-quantity">
                                                            <div className="wg-quantity mb-0">
                                                                <span className="btn-quantity minus-btn" onClick={() => handleQuantityChange(elem.id, -1)}>
                                                                    <svg className="d-inline-block" width={9} height={1} viewBox="0 0 9 1" fill="currentColor">
                                                                        <path d="M9 1H5.14286H3.85714H0V1.50201e-05H3.85714L5.14286 0L9 1.50201e-05V1Z" />
                                                                    </svg>
                                                                </span>
                                                                <input type="text" name="number" value={elem?.quantity} disabled />
                                                                {elem?.quantity > 9 ? <span className="btn-quantity plus-btn" >
                                                                    <svg className="d-inline-block" width={9} height={9} viewBox="0 0 9 9" fill="currentColor">
                                                                        <path d="M9 5.14286H5.14286V9H3.85714V5.14286H0V3.85714H3.85714V0H5.14286V3.85714H9V5.14286Z" />
                                                                    </svg>
                                                                </span> : <span className="btn-quantity plus-btn" onClick={() => handleQuantityChange(elem.id, 1)}>
                                                                    <svg className="d-inline-block" width={9} height={9} viewBox="0 0 9 9" fill="currentColor">
                                                                        <path d="M9 5.14286H5.14286V9H3.85714V5.14286H0V3.85714H3.85714V0H5.14286V3.85714H9V5.14286Z" />
                                                                    </svg>
                                                                </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="tf-cart-item_total" cart-data-title="Total">
                                                        <div className="cart-total">₹{(elem?.indiazona_price * elem?.quantity).toFixed(2)}</div>
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-12 col-md-xl-12 col-lg-8 col-md-8 col-12">
                                    <div className="tf-page-cart-footer">
                                        <div className="tf-cart-footer-inner">

                                            <div className="tf-page-cart-checkout">
                                                <div className="tf-cart-totals-discounts">
                                                    <h6>Subtotal</h6>
                                                    <span className="total-value">₹ {cart?.sub_total}</span>
                                                </div>
                                                <p className="tf-cart-tax">
                                                    Taxes and shipping calculated at checkout.
                                                </p>
                                                <div className="form-check cart-checkbox">
                                                    <input className="form-check-input" type="checkbox" checked={formik.values.isChecked} onChange={handleCheckboxChange} />
                                                    <p htmlFor="I-agree-with-me" className="fw-4">
                                                        By placing this order, you agree to our <Link
                                                            to="https://portal.indiazona.in/terms"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                window.open("https://portal.indiazona.in/terms", "_blank", "noopener,noreferrer");
                                                            }}
                                                        >terms and conditions</Link> of Use
                                                    </p>
                                                </div>
                                                <div className="error-message" style={{ color: 'red' }}>
                                                    {formik.errors.isChecked}
                                                </div>
                                                <div className="cart-checkout-btn" style={{ cursor: 'pointer' }}>
                                                    <button
                                                        className={`tf-btn w-100 btn`}
                                                        style={{ backgroundColor: formik.values.isChecked && '#000', color: formik.values.isChecked ? '#fff' : '#000' }}
                                                        disabled={formik.values.isChecked ? false : true}
                                                        onClick={handleCheckout}
                                                    >
                                                        <span>Check out</span>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="text-center wishlist-section">
                        <p className='mb-2'>Your Shopping Cart is Empty!</p>
                        {!user?.id ? (
                            <>
                                <p><Link to='/login'>Sign in</Link> to link items to your account, or view items already in your account</p>
                                <Button outlined onClick={() => navigate('/login')}>Login</Button>
                            </>
                        ) :
                            <Button outlined onClick={() => navigate('/')}>Continue Shopping</Button>
                        }
                    </div>
                )}
            </div>
        </section>

    )
}

export default CartDetails