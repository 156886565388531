import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cansleSingleOrder, downloadInvo, getOrderTracking, } from '../../middlewares/cart/order';
import DeliveryStatus from '../../Components/Products/Order/DeliveryStatus';
import OrderTotal from './Comman/OrderTotal';
import { cancelSingleOrderReset, invoiceReset } from '../../slices/orderSlice';
import { toast } from 'react-toastify';
import CancelOrder from '../../Components/Model/OrderTrack/CancelOrder';
import ChangeAddressModal from '../../Components/Model/OrderTrack/ChangeAddressModel';
import CancleConfOrder from '../../Components/Model/OrderTrack/CancleConfOrder';
import ChatWidget from '../ChatWidget';
import { isWidgetOpened, toggleWidget } from 'react-chat-widget';
const OrderTrack = () => {
  const location = useLocation();
  const data = location.state
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false);
  const [ConfmodalOpen, setConfmodalOpen] = useState(false);
  const [addressModal, setAddressModel] = useState(false);
  const [TypeOFOrder, setTypeOfOrder] = useState(0)
  const [isChatOpen, setIsChatOpen] = useState(false);
  const IsLogedin = localStorage.getItem('IN_USER_DATA');

  const toggleChatWidget = () => {
    setIsChatOpen(!isChatOpen);
    handleOpenChatWidget();
  };

  // Function to open the chat widget and load chat history if not already opened
  const handleOpenChatWidget = () => {
    if (!isWidgetOpened()) {
      toggleWidget();
    }
  };

  useEffect(() => {
    !IsLogedin ? navigate('/') : <></>;
  }, [IsLogedin]);

  useEffect(() => {
    const bodyFormData = new FormData();
    bodyFormData.append("id", data?.order_id);
    dispatch(getOrderTracking(bodyFormData));
  }, [dispatch]);

  const { cancelOrderMessage, cancelSingleOrder, cancelOrderError, invoiceDownload, invoiceMessage, invoiceLoading,
    getOrderTrack } = useSelector((state) => state?.order);
  const { order, status, track, estimated_delivery } = getOrderTrack;
  const parsedAddress = order?.shipping_address ? JSON.parse(order.shipping_address) : null;
  const productData = order?.orderDetails?.find((o) => o.product.id === data?.pro_id)

  useEffect(() => {
    if (cancelOrderMessage) {
      setConfmodalOpen(true)
      setTypeOfOrder(1)
      const bodyFormData = new FormData();
      bodyFormData.append("id", data?.order_id);
      dispatch(getOrderTracking(bodyFormData));
    }
    dispatch(cancelSingleOrderReset())
  }, [dispatch, cancelOrderMessage]);

  useEffect(() => {
    if (order?.delivery_status === 'Cancelled') {
      setTypeOfOrder(1)
    }
  }, [dispatch, getOrderTrack]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleConfModalClose = () => {
    setConfmodalOpen(false);
  };

  useEffect(() => {
    if (invoiceMessage) {
      toast.success(invoiceMessage);
      window.open(invoiceDownload, '_blank');
      dispatch(invoiceReset());
    }
  }, [invoiceMessage, invoiceDownload, dispatch]);

  const downloadInvoice = (id) => {
    const bodyFormData = new FormData();
    bodyFormData.append("id", id);
    dispatch(downloadInvo(bodyFormData))
  }

  console.log(track);

  return (
    <>
      <section className="page-title">
        <div className="pattern-layer"></div>
        <div className="container-full">
          <div className="content-box">
            <h3>Order Tracking</h3>
          </div>
        </div>
      </section>
      <section className="profile-section profile-wrapper">
        {/* <SideBarManu /> */}
        <section className='order-tracking'>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12">
                <div className="order-wpr-left ">
                  <div className="order-product">
                    <img src={productData?.product?.photos[0]?.path} alt="order-img" />
                  </div>
                  <div className="order-content">
                    <h6>{productData?.product?.brand_name}</h6>
                    <p>{productData?.product?.name}</p>
                    <h5>
                      <span>₹. {productData?.price}</span>
                    </h5>
                    <div className='w-100 mt-4'>
                      <button className="btn style-1 btn-primary-filled-slide p-0" type='button' onClick={() => navigate(`/${productData?.product?.category_slug}/product-details/${productData?.product?.id}`)}>
                        <span>View Product</span>
                      </button>
                      {/* <a className="btn style-2 btn-outline-primary-slide p-0">
                        <span>Reorder Product</span>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-sm-12">
                <div className="order-wpr-right">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item active"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item"><img src="/assets/images/svg-img/arrow-right.svg" alt="arrow-right" /></li>
                      <li className="breadcrumb-item" onClick={() => navigate('/order-details', { state: { Id: order?.id } })} style={{ cursor: 'pointer' }}><Link>Orders Details </Link></li>
                      <li className="breadcrumb-item"><img src="/assets/images/svg-img/arrow-right.svg" alt="arrow-right" /></li>
                      <li className="breadcrumb-item" aria-current="page">ID {order?.code}</li>
                    </ol>
                  </nav>

                  <div className='row justify-content-between align-items-center mt-2'>
                    <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                      <div className='order-Id-Number'>
                        <h4 className='mb-lg-0 mb-md-2 mb-2'>Order ID: {order?.code}</h4>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12">
                      <div className="row justify-content-lg-end justify-content-md-end justify-content-sm-start">
                        <div className="col-xl-4 col-lg-6 col-md-12 col-6">
                          <div className='order-Invoice-btn'>
                            <button className='Invoice-btn' onClick={() => downloadInvoice(order?.id)} disabled={invoiceLoading}><img src="/assets/images/svg-img/Invoice.svg" alt="Invoice" /> {invoiceLoading ? "Processing..." : "Invoice"}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row align-items-center mt-2'>
                    <div className=" col-xxl-3 col-xl-3 col-lg-5 col-md-12 col-sm-12 ">
                      <div className='order-date mt-md-2 mb-2 mb-lg-0'>
                        <h4>
                          <span>Order date: </span> {order?.order_date}
                        </h4>

                      </div>
                    </div>
                    <div className="col-xxl-7 col-xl-8 col-lg-6 col-md-12 col-sm-12">
                      <div className='order-delivery mt-md-2 mt-sm-2'>
                        <h6 className='delivery-date'>
                          <img src="/assets/images/svg-img/truck-tick.svg" alt="truck-tick" />
                          {estimated_delivery ? `Estimated delivery: ${estimated_delivery}` : "No estimated delivery available"}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <DeliveryStatus type={TypeOFOrder} status={status} />
                  </div>

                  <div className="View-detailed tracking">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <button className="accordion-button collapsed mt-3" type="button" data-bs-toggle="collapse" data-bs-target="#detailed" aria-expanded="false" aria-controls="detailed">
                          View detailed tracking
                        </button>
                        <div id="detailed" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            {track?.map((track, index) => (
                              <div className="accordion-content" key={index}>
                                <h6>{track.title}</h6>
                                {track.message ? (
                                  <p>{track.message}</p>
                                ) : (
                                  <p>No additional information available</p>
                                )}
                                {track.location ? (
                                  <p>{track.location}</p>
                                ) : (
                                  ''
                                )}
                              </div>
                            ))}
                            {track?.length === 0 && (
                              <div className="accordion-content">
                                <p>No tracking information available</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="delivery-wpr">
                    <div className="row justify-content-between">
                      <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                        <div className="row justify-content-between">
                          <div className='col-9 delivery-inner'>
                            <h6><strong>Delivery</strong></h6>
                            <p><small>Address</small></p>
                            <>
                              {parsedAddress ? (
                                <>
                                  <p>{parsedAddress?.name}</p>
                                  <p>{parsedAddress?.address}</p>
                                  <p>{parsedAddress?.postal_code}</p>
                                  <p>{parsedAddress?.state}, {parsedAddress?.country}</p>
                                  <p className='mt-lg-4 mt-md-3 mt-1'>Mob: <strong>+91 {parsedAddress?.phone}</strong></p>
                                </>
                              ) : (
                                <p>No shipping address available</p>
                              )}
                            </>
                          </div>
                          <div className='col-3 change-address'>
                            {
                              getOrderTrack?.order?.delivery_status === 'Cancelled' ? '' : getOrderTrack?.order?.allow_change_address === 1 && <button className="style-2 btn-outline-primary-slide p-0 Change-address-btn" type='button' onClick={() => setAddressModel(true)}>
                                <span>Change</span>
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-12 col-sm-12 mb-3">
                        <div className='order-help-wpr'>
                          <h6>Need Help</h6>
                          <div className='Chat-us'>
                            <p onClick={toggleChatWidget} style={{ cursor: 'pointer' }}><span className='img-icons px-1'> <img src="/assets/images/svg-img/Chat-us.svg" alt="chat-us" /> </span> Chat with us  <span className='img-icons px-1'><img src="/assets/images/svg-img/arrow-up.svg" alt="arrow-up" /></span></p>
                            {
                              getOrderTrack?.order?.allow_return === 1 && <p>
                                <span onClick={() => navigate('/order-return', { state: { oId: order?.id, proId: productData?.product?.id } })} style={{ cursor: 'pointer', color: '#667085' }}>
                                  <span className='img-icons px-1'>
                                    <img src="/assets/images/svg-img/product-box.svg" alt="product-box" />
                                  </span>
                                  <span>
                                    Not happy with Product
                                  </span>
                                  <span className='img-icons px-1'>
                                    <img src="/assets/images/svg-img/arrow-up.svg" alt="arrow-up" />
                                  </span>
                                </span>
                              </p>
                            }
                          </div>
                          {getOrderTrack?.order?.order_in_transit === 0 && (
                            <div className='cancel-order Chat-us'>
                              <p style={{ cursor: 'pointer' }} onClick={() => setModalOpen(true)}><span className='img-icons px-1'> <img src="/assets/images/svg-img/cancel-order.svg" alt="cancel-order" /> </span> Cancel Order <span className='img-icons px-1'><img src="/assets/images/svg-img/arrow-up-cancel.svg" alt="arrow-up" /></span></p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {isChatOpen && <ChatWidget />}
                  </div>
                  <OrderTotal order_summary={order?.order_summary} product={data?.pro_id} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </section >
      <CancelOrder data={getOrderTrack} show={modalOpen} onHide={handleModalClose} />
      <CancleConfOrder show={ConfmodalOpen} onHide={handleConfModalClose} />
      <ChangeAddressModal
        data={data}
        show={addressModal}
        onHide={() => setAddressModel(false)}
      />
    </>
  )
}

export default OrderTrack