import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../../Pages/Home';
import ProductList from '../../Pages/ProductList';
import Login from '../../AuthComponents/Login';
import Forgotpassword from '../../AuthComponents/Forgotpassword';
import ProductDetailsPage from '../../Pages/ProductDetailsPage';
import Otp from '../../AuthComponents/Otp';
import Resetpassword from '../../AuthComponents/Resetpassword';
import CompareProduct from '../../Pages/CompareProduct';
import WishList from '../../Pages/WishList';
import Cart from '../../Pages/Cart';
import SingelSortVideo from '../../Components/Common/SingelSortVideo';
import VerifyEmail from '../../AuthComponents/VerifyEmail';
import AccountProfile from '../../Pages/UserPages/AccountProfile';
import Dashboard from '../../Pages/UserPages/Dashboard';
import OrdersPage from '../../Pages/UserPages/OrdersPage';
import ReturnPage from '../../Pages/UserPages/Order/ReturnPage';
import UserAddress from '../../Pages/UserPages/UserAddress';
import OrderDetails from '../../Pages/UserPages/OrderDetails';
import BlogPage from '../../Pages/BlogPage';
import BlogDetails from '../../Pages/BlogDetails';
import MobileNumber from '../../AuthComponents/MobileNumber';
import MobileVerify from '../../AuthComponents/MobileVerify';
import Layout from '../Layout';
import OrderSuccessPage from '../../Pages/UserPages/OrderSuccessPage';
import PageNotFound from '../../Pages/PageNotFound';
import TopScroll from '../../Components/Common/TopScroll';
import Layout2 from './Layout2';
import Aboutus from '../../Pages/PolicyPages/Aboutus';
import FAQ from '../../Pages/PolicyPages/FAQ';
import ContectUs from '../../Pages/ContectUs';
import MobileSearchBar from '../../Components/Common/MobileSearchBar';
import CartLayout from '../CartLayout';
import ShippingAddress from '../../Pages/CheckOut/ShippingAddress';
import PaymentPage from '../../Pages/CheckOut/PaymentPage';
import LandingPage from '../../Pages/Landing-Page/LandingPage';
import OrderIssue from '../../Pages/UserPages/OrderIssue';
import Compliance from '../../Pages/PolicyPages/Compliance';
import VendorFaqs from '../../Pages/PolicyPages/VendorFaqs';
import CancelPolicy from '../../Pages/PolicyPages/CancelPolicy';
import REPolicy from '../../Pages/PolicyPages/REPolicy';
import OrderTrack from '../../Pages/UserPages/OrderTrack';
import ReturnDetails from '../../Pages/UserPages/Order/ReturnDetails';
import OrdersReturnPage from '../../Pages/UserPages/OrdersReturnPage';

const User = () => {

  return (
    <>
      <TopScroll />
      <Routes>
        <Route path='/' element={<Navigate to='/' />} />
        <Route path="/" element={<Layout />}>

          <Route index element={<Home />} />
          {/* <Route path='home' element={<Home />} /> */}

          <Route path=":category/product-list" element={<ProductList />} />
          <Route path=":category/product-details/:id" element={<ProductDetailsPage />} />
          <Route path=":category/product-details/:product_id" element={<ProductDetailsPage />} />

          <Route path="compare-products" element={<CompareProduct />} />
          <Route path="wishlist" element={<WishList />} />

          <Route path="short-videos" element={<SingelSortVideo />} />

          <Route path="profile" element={<AccountProfile />} />
          <Route path="user-dashboard" element={<Dashboard />} />
          <Route path="user-orders" element={<OrdersPage />} />
          <Route path="user-orders-tracking" element={<OrderIssue />} />
          <Route path="track-order" element={<OrderTrack />} />
          <Route path="order-details" element={<OrderDetails />} />
          <Route path="order-return" element={<ReturnPage />} />
          <Route path="return-orders" element={<OrdersReturnPage />} />
          <Route path='track-order-return' element={<ReturnDetails />} />
          <Route path="user-address" element={<UserAddress />} />

          <Route path="blog-page" element={<BlogPage />} />
          <Route path="blog-details" element={<BlogDetails />} />
          <Route path='order-confirmation' element={<OrderSuccessPage />} />

          <Route path='/*' element={<PageNotFound />} />
        </Route>

        <Route path="/" element={<CartLayout />}>
          <Route path="cart" element={<Cart />} />
          <Route path='checkout/shipping' element={<ShippingAddress />} />
          <Route path='checkout/payment' element={<PaymentPage />} />
        </Route>

        <Route path='/login' element={<Login />} />
        <Route path='/phone' element={<MobileNumber />} />
        <Route path='/forgot-password' element={<Forgotpassword />} />
        <Route path='/email-verification' element={<VerifyEmail />} />
        <Route path='/otp-verification' element={<Otp />} />
        <Route path='/mobile-verification' element={<MobileVerify />} />
        <Route path='/reset-password' element={<Resetpassword />} />

        {/* <Route path='/landing-page' element={<LandingPage />} /> */}

        <Route path='/' element={<Layout2 />}>
          <Route path='/about-us' element={<Aboutus />} />
          <Route path='/customer-faqs' element={<FAQ />} />
          <Route path='/contect-us' element={<ContectUs />} />
          <Route path='/compliance' element={<Compliance />} />
          <Route path='/vendor-faqs' element={<VendorFaqs />} />
          <Route path='/cancel-policy' element={<CancelPolicy />} />
          <Route path='/return-policy' element={<REPolicy />} />
        </Route>

        <Route path='/mobileSearchBar' element={<MobileSearchBar />} />

        <Route path='*' element={<Navigate to='/' />} />
      </Routes>



    </>
  )
}

export default User