import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSidebarFiltersData } from '../../middlewares/products/sidebarFilter';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { fetchProducts } from '../../middlewares/products/product';


const FilterProductList = () => {
    const navigate = useNavigate();
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchOpen2, setSearchOpen2] = useState(false);
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [showAllBrands, setShowAllBrands] = useState(false);
    const [showAllColors, setShowAllColors] = useState(false);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [colors, setColors] = useState([]);
    const [mainCategoriesId, setmainCategoriesId] = useState();
    const dispatch = useDispatch();
    const { category } = useParams();
    const { homeCategory, allCategory } = useSelector((state) => state.category);
    const { productsList } = useSelector((state) => state.product);
    console.log(productsList, 'productsList');


    useEffect(() => {
        if (allCategory) {
            const mainCategories = allCategory?.find((e) =>
                e?.slug === category
            );
            setmainCategoriesId(mainCategories)
        }
    }, [allCategory])

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedColors, setSelectedColors] = useState([]);

    const formik = useFormik({
        initialValues: {
            brandName: '',
            colorName: '',
        },
    });
    const { sidebarFilter } = useSelector((state) => state.sidebarFilter);
    useEffect(() => {
        if (category) {
            const payload = { slug: category };
            dispatch(getSidebarFiltersData(payload));
        }
    }, [category]);

    useEffect(() => {
        if (sidebarFilter?.sub_categories) {
            setCategories(sidebarFilter.sub_categories);
        }
        if (sidebarFilter?.brands) {
            setBrands(sidebarFilter.brands);
        }
        if (sidebarFilter?.colors) {
            setColors(sidebarFilter.colors);
        }
        resetSelectedFilters();
    }, [sidebarFilter]);

    useEffect(() => {
        if (formik.values.brandName === '') {
            setBrands(sidebarFilter?.brands || []);
        } else {
            const searchValue = formik.values.brandName.toLowerCase();
            const filteredBrands = sidebarFilter?.brands?.filter(brand =>
                brand.name.toLowerCase().includes(searchValue)
            );
            setBrands(filteredBrands || []);
        }
    }, [formik.values.brandName, sidebarFilter]);

    useEffect(() => {
        if (formik.values.colorName === '') {
            setColors(sidebarFilter?.colors || []);
        } else {
            const searchValue = formik.values.colorName.toLowerCase();
            const filteredColors = sidebarFilter?.colors?.filter(color =>
                color.name.toLowerCase().includes(searchValue)
            );
            setColors(filteredColors || []);
        }
    }, [formik.values.colorName, sidebarFilter]);

    const resetSelectedFilters = () => {
        const categoryid = allCategory?.find((e) => e?.slug === category)
        setSelectedCategories([]);
        setSelectedBrands([]);
        setSelectedColors([]);
        const filter = [{
            category_id: categoryid?.id,
            sub_categories: [],
            brands: [],
            colors: []
        }]
        const formData = new FormData();
        formData.append("current_page", 1);
        formData.append("current_page", "filtterpage");
        formData.append("filter", JSON.stringify(filter));
        dispatch(fetchProducts(formData));
    };

    const handleFilterChange = (filterType, value) => {
        if (filterType === 'categories') {
            setSelectedCategories(prevSelected => {
                const updatedCategories = prevSelected.includes(value)
                    ? prevSelected.filter(item => item !== value)
                    : [...prevSelected, value];

                const filter = [{
                    category_id: mainCategoriesId?.id,
                    sub_categories: updatedCategories,
                    brands: selectedBrands,
                    colors: selectedColors
                }];
                const formData = new FormData();
                formData.append("current_page", 1);
                formData.append("filter", JSON.stringify(filter));
                dispatch(fetchProducts(formData));
                return updatedCategories;
            });
        } else if (filterType === 'brands') {
            setSelectedBrands(prevSelected => {
                const updatedBrands = prevSelected.includes(value)
                    ? prevSelected.filter(item => item !== value)
                    : [...prevSelected, value];

                const filter = [{
                    category_id: mainCategoriesId?.id,
                    sub_categories: selectedCategories,
                    brands: updatedBrands,
                    colors: selectedColors
                }];
                const formData = new FormData();
                formData.append("current_page", 1);
                formData.append("filter", JSON.stringify(filter));
                dispatch(fetchProducts(formData));
                return updatedBrands;
            });
        } else if (filterType === 'colors') {
            setSelectedColors(prevSelected => {
                const updatedColors = prevSelected.includes(value)
                    ? prevSelected.filter(item => item !== value)
                    : [...prevSelected, value];

                const filter = [{
                    category_id: mainCategoriesId?.id,
                    sub_categories: selectedCategories,
                    brands: selectedBrands,
                    colors: updatedColors
                }];

                const formData = new FormData();
                formData.append("current_page", 1);
                formData.append("filter", JSON.stringify(filter));
                dispatch(fetchProducts(formData));

                return updatedColors;
            });
        }
    };
    const toggleSearch = () => setSearchOpen(!searchOpen);
    const toggleSearch2 = () => setSearchOpen2(!searchOpen2);
    const toggleShowCategories = () => setShowAllCategories(!showAllCategories);
    const toggleShowBrands = () => setShowAllBrands(!showAllBrands);
    const toggleShowColors = () => setShowAllColors(!showAllColors);

    return (
        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12 d-none d-md-block d-lg-block">
            <div className="filter-position-sticky">
                <div className="box-shadow condition-listing-vertical-Filters filter-mobile-view-hide">
                    <div className="filters-box categories-Boxs">
                        <ul className="d-flex justify-content-between aligin-items-center">
                            <li className="d-flex aligin-items-center gap-2" style={{ alignItems: 'center' }}>
                                <h6 className="filters-titel mb-0">Filters</h6><span className="filter-count">0</span>
                            </li>
                            <li>
                                <button className="btn clear-Btn" onClick={resetSelectedFilters}>Clear All</button>
                            </li>
                        </ul>
                    </div>
                    {categories.length > 0 && (
                        <div className="filters-box categories-Boxs">
                            <ul>
                                <h6 className="filters-titel">Categories</h6>
                                {categories.slice(0, 5).map((category, index) => (
                                    <li key={index}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={category.id}
                                                name={category.name}
                                                checked={selectedCategories.includes(category.id)}
                                                onChange={() => handleFilterChange('categories', category.id)}
                                            />
                                            <label className="form-check-label" htmlFor={category.name}>
                                                {category.name}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                                {showAllCategories && categories.slice(5).map((category, index) => (
                                    <li key={index}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={category.id}
                                                name={category.name}
                                                checked={selectedCategories.includes(category.id)}
                                                onChange={() => handleFilterChange('categories', category.id)}
                                            />
                                            <label className="form-check-label" htmlFor={category.name}>
                                                {category.name}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                                {categories.length > 5 && (
                                    <button className="more-btn btn" onClick={toggleShowCategories}>
                                        {showAllCategories ? 'Show Less' : `+ ${categories.length - 5} more`}
                                    </button>
                                )}
                            </ul>
                        </div>
                    )}
                    <div className="filters-box categories-Boxs">
                        <ul>
                            <div className="mb-2 d-flex align-items-center gap-2 justify-content-between" style={{ alignItems: 'center' }}>
                                <h6 className="filters-titel mb-0">Brand</h6>
                                <div className={`input-box filter-search ${searchOpen ? 'open' : ''}`}>
                                    <input
                                        className="input-search-fill"
                                        type="text"
                                        placeholder="Search..."
                                        name="brandName"
                                        onChange={formik.handleChange}
                                        value={formik.values.brandName}
                                    />
                                    <span className="search search-icon-btn">
                                        <span onClick={() => setSearchOpen(true)}>
                                            <i className="fa-solid fa-magnifying-glass search-icon search-icon-btn" />
                                        </span>
                                    </span>
                                    <span onClick={toggleSearch}>
                                        <i className="fa-solid fa-xmark close-icon close-icon-btn" />
                                    </span>
                                </div>
                            </div>

                            {brands.length === 0 ? (
                                <li className="text-center">Brand is not available</li>
                            ) : (
                                <>
                                    {brands.slice(0, 5).map((brand, index) => (
                                        <li key={index}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={brand.id}
                                                    name={brand.name}
                                                    checked={selectedBrands.includes(brand.id)}
                                                    onChange={() => handleFilterChange('brands', brand.id)}
                                                />
                                                <label className="form-check-label" htmlFor={brand.name}>
                                                    {brand.name}
                                                </label>
                                            </div>
                                        </li>
                                    ))}

                                    {showAllBrands && brands.slice(5).map((brand, index) => (
                                        <li key={index}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={brand.id}
                                                    name={brand.name}
                                                    checked={selectedBrands.includes(brand.id)}
                                                    onChange={() => handleFilterChange('brands', brand.id)}
                                                />
                                                <label className="form-check-label" htmlFor={brand.name}>
                                                    {brand.name}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                    {brands.length > 5 && (
                                        <button className="more-btn btn" onClick={toggleShowBrands}>
                                            {showAllBrands ? 'Show Less' : `+ ${brands.length - 5} more`}
                                        </button>
                                    )}
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="filters-box categories-Boxs">
                        <ul>
                            <div className="mb-2 d-flex aligin-items-center gap-2 justify-content-between" style={{ alignItems: 'center' }}>
                                <h6 className="filters-titel mb-0">Color</h6>
                                <div className={`input-box filter-search ${searchOpen2 ? 'open' : ''}`}>
                                    <input
                                        className="input-search-fill"
                                        type="text"
                                        placeholder="Search by name..."
                                        name="colorName"
                                        value={formik.values.colorName}
                                        onChange={formik.handleChange}
                                    />
                                    <span className="search search-icon-btn">
                                        <span onClick={() => setSearchOpen2(true)}>
                                            <i className="fa-solid fa-magnifying-glass search-icon search-icon-btn" />
                                        </span>
                                    </span>
                                    <span onClick={toggleSearch2}>
                                        <i className="fa-solid fa-xmark close-icon close-icon-btn" />
                                    </span>
                                </div>
                            </div>

                            {colors.length === 0 ? (
                                <li className="text-center">Color is not available</li>
                            ) : (
                                <>
                                    {/*   */}
                                    {colors.slice(0, 5).map((color, i) => (
                                        <li key={i} className={`li-circle `}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={color.code}
                                                    checked={selectedColors.includes(color.code)}
                                                    onChange={() => handleFilterChange('colors', color.code)}
                                                />
                                                <label className="form-check-label d-flex justify-content-between align-items-center">
                                                    <button
                                                        style={{ backgroundColor: color.name }}
                                                        className='color-btn-filter'
                                                    // onClick={() => handleColorClick(elem?.code)}
                                                    >
                                                    </button>
                                                    {color.name}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                    {showAllColors && colors.slice(5).map((color, i) => (
                                        <li key={i}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={color.name}
                                                    checked={selectedColors.includes(color.code)}
                                                    onChange={() => handleFilterChange('colors', color.code)}
                                                />
                                                <label className="form-check-label d-flex justify-content-between align-items-center">
                                                    <button
                                                        style={{ backgroundColor: color.name }}
                                                        className='color-btn-filter'
                                                    // onClick={() => handleColorClick(elem?.code)}
                                                    >
                                                    </button>
                                                    {color.name}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                    {colors.length > 5 && (
                                        <button className="more-btn btn" onClick={toggleShowColors}>
                                            {showAllColors ? 'Show Less' : `+ ${colors.length - 5} more`}
                                        </button>
                                    )}
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterProductList;
