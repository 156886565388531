import chatService from "../../services/chat";
import {
  chatRecieverError,
  chatRecieverRequest,
  chatRecieverSuccess,
  getError,
  getRequest,
  getSuccess,
  sendError,
  sendRequest,
  sendSuccess,
} from "../../slices/chatSlice";

export const chatRecieverList = (payload) => {
  return (dispatch) => {
    dispatch(chatRecieverRequest());
    chatService.chatReciever(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(chatRecieverSuccess({ data, message }));
        } else {
          dispatch(chatRecieverError({ message: error }));
        }
      })
      .catch((error) => {
        dispatch(chatRecieverError({ data: [], message: error }));
      });
  };
};

export const sendChatMessage = (payload) => {
  return (dispatch) => {
    dispatch(sendRequest());

    return chatService.sendChat(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(sendSuccess({ message }));
          return data;
        } else {
          dispatch(sendError(error));
          throw new Error(error);
        }
      })
      .catch((error) => {
        dispatch(sendError({ data: [], message: error }));
        throw error;
      });
  };
};

export const getChatMessage = (payload) => {
  return (dispatch) => {
    dispatch(getRequest());
    chatService.getChat(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(getSuccess({ data, message }));
        } else {
          dispatch(getError(error));
        }
      })
      .catch((error) => {
        dispatch(getError({ data: [], message: error }));
      });
  };
};
