import { LOCALSTORAGE_CART_KEY } from "../../config/constants";
import LocalstorageService from "../../helpers/localstorage-services";
import CartService from "../../services/cart";
import {
  cartRequest,
  cartSuccess,
  cartError,
  cartAddRequest,
  cartAddSuccess,
  cartAddError,
  cartRemoveRequest,
  cartRemoveSuccess,
  cartRemoveError,
  cartUpdateRequest,
  cartUpdateSuccess,
  cartUpdateError,
  updateCartCount,
} from "../../slices/cart"

export const getCartData = (payload) => {
  return (dispatch) => {
    dispatch(cartRequest())
    CartService.getCart(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          LocalstorageService.setInLocalStorage(LOCALSTORAGE_CART_KEY, data?.cart_item || [])
          dispatch(updateCartCount(data?.cart_item?.length));
          dispatch(cartSuccess({ data, message }));
        } else {
          dispatch(cartError({ data, message: error }));
        }
      }).catch((error) => {
        dispatch(cartError({ data: [], message: error }));
      });
  };
};

export const addToCart = (payload) => {
  return (dispatch) => {
    dispatch(cartAddRequest());
    CartService.addCart(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(cartAddSuccess({ data, message }));
        } else {
          dispatch(cartAddError(error));
        }
      }).catch((error) => {
        dispatch(cartAddError({ data: [], message: error }));
      });
  };
};

export const updateCart = (payload) => {
  return (dispatch) => {
    dispatch(cartUpdateRequest());
    CartService.updateQuantity(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(cartUpdateSuccess({ data, message }));
        } else {
          dispatch(cartUpdateError({ message: error }));
        }
      })
      .catch((error) => {
        dispatch(cartUpdateError({ message: error.message }));
      });
  };
};

export const removeCartItem = (payload) => {
  return (dispatch) => {
    dispatch(cartRemoveRequest())
    CartService.removeCart(payload).then((response) => {
      const { status, data, message, error } = response.data;
      if (status === 1) {
        dispatch(cartRemoveSuccess({ data, message }));
      } else {
        dispatch(cartRemoveError(error));
      }
    }).catch((error) => {
      dispatch(cartRemoveError({ data: [], message: error }));
    });
  }
}