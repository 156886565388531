import { ChevronDown, ShoppingBag } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { AllCategories, fetchHomeCategories, fetchsubCategories } from '../../middlewares/category';
import { fetchProducts } from '../../middlewares/products/product';

const ProductListCateogry = ({ mobileMenu, setMobileMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subMenuActive, setSubMenuActive] = useState(null);
  const [currentMenuTitle, setCurrentMenuTitle] = useState('');

  const { homeCategory, allCategory } = useSelector((state) => state.category);

  const showSubMenu = (title) => {
    setCurrentMenuTitle(title);
    setSubMenuActive(title);
  };

  const hideSubMenu = () => {
    setSubMenuActive(null);
    setCurrentMenuTitle('');
  };

  useEffect(() => {
    dispatch(fetchHomeCategories())
  }, [dispatch])

  useEffect(() => {
    dispatch(AllCategories())
  }, [dispatch])
  const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };
  const handleSubCategory = (subCategory) => {
    const filter = [{
      category_id: subCategory.category_id,
      sub_categories: [subCategory.id],
    }];
  
    const formData = new FormData();
    formData.append("current_page", 1);
    formData.append("filter", JSON.stringify(filter));
    
    dispatch(fetchProducts(formData));
  };
 
  return (
    <section className="header header-menu" >
      <div className="container-fluid position-relative">
        <div className="row v-center">
          <div className="header-item item-center">
            <div className={`menu-overlay ${mobileMenu ? 'active' : ''}`} onClick={() => setMobileMenu(false)} />
            <nav className={`menu ${mobileMenu ? 'active' : ''}`}>
              <div className={`mobile-menu-head ${subMenuActive ? 'active' : ''}`} >
                <div className="go-back" onClick={hideSubMenu}><i className="fa fa-angle-left" /></div>
                <div className="current-menu-title">{currentMenuTitle}</div>
                <div className="mobile-menu-close" onClick={() => setMobileMenu(false)}>×</div>
              </div>
              <ul className="menu-main">
                <li className={`menu-item-has-children header-bg ${subMenuActive === 'Categories' ? 'active' : ''}`}>
                  <a className="px-3" onClick={() => showSubMenu('Categories')}>
                    Categories
                    <span className="seeAll" style={{ fontSize: 12, paddingInline: 15 }}>
                      (See All)
                    </span>
                    <ChevronDown className='iconFont' /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Mobiles' ? 'active' : ''}`}>

                    {allCategory?.map((category, index) =>
                      <div className="list-item"> <ul> <li><a onClick={() => navigate(`${category?.slug}/product-list`)} style={{ cursor: 'pointer' }}>{category?.short_name}</a></li>
                      </ul>
                      </div>
                    )}

                    {/* <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div> */}
                  </div>
                  {/* <div className={`sub-menu single-column-menu ${subMenuActive === 'Categories' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <ul>
                      {homeCategory.map((category, i) => (
                        <li key={i}>
                          <Link to={`/${category.slug}/product-list`}>{category.short_name}</Link>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                </li>
                <li><Link to='/'>Home</Link>
                </li>
                {console.log("home category==>", homeCategory)}
                {homeCategory.map((category) => (
                  <li key={category.id} className={`menu-item-has-children ${subMenuActive === category.name ? 'active' : ''}`}>
                    <a onClick={() => showSubMenu(category.name)}>
                      {category.short_name} <ChevronDown fontSize={1} />
                    </a>

                    <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === category.name ? 'active' : ''}`}>
                      {category.sub_categories.length > 0 ? (
                        chunkArray(category.sub_categories, Math.ceil(category.sub_categories.length / (category.sub_categories.length > 10 ? 4 : 2)))
                          .map((subCategoryChunk, index) => (
                            <div className="list-item" key={index}>
                              {index === 0 && (
                                <h4 className="title" style={{ color: '#f15b27' }}>
                                  <Link to={`/${category.slug}/product-list`}>
                                    {category.name}
                                  </Link>
                                  {category.name}
                                </h4>
                              )}
                              <ul>
                                {subCategoryChunk.map((subCategory) => (
                                  <li key={subCategory.id}>
                                    <Link
                                      onClick={() => handleSubCategory(subCategory)}
                                      to={`/${category.slug}/product-list`}
                                      style={{ cursor: 'pointer' }}>
                                      {subCategory.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))
                      ) : (
                        <div>No subcategories available</div>
                      )}
                    </div>
                  </li>
                ))}

                {/* <li className={`menu-item-has-children ${subMenuActive === 'Mobiles' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Mobiles')}>Mobiles<ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Mobiles' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Women's Fashion</h4>
                      <ul>
                        <li><a href="#">Sarees</a></li>
                        <li><a href="#">Sandals</a></li>
                        <li><a href="#">Watchs</a></li>
                        <li><a href="#">Shoes</a></li>
                      </ul>
                      <h4 className="title">Furniture</h4>
                      <ul>
                        <li><a href="#">Chairs</a></li>
                        <li><a href="#">Tables</a></li>
                        <li><a href="#">Doors</a></li>
                        <li><a href="#">Bed</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Home, Kitchen</h4>
                      <ul>
                        <li><a href="#">Kettle</a></li>
                        <li><a href="#">Toaster</a></li>
                        <li><a href="#">Dishwasher</a></li>
                        <li><a href="#">Microwave oven</a></li>
                        <li><a href="#">Pitcher</a></li>
                        <li><a href="#">Blender</a></li>
                        <li><a href="#">Colander</a></li>
                        <li><a href="#">Tureen</a></li>
                        <li><a href="#">Cookware</a></li>
                      </ul>
                    </div>
                    <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div>
                  </div>
                </li>
                <li className={`menu-item-has-children ${subMenuActive === 'Furniture' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Furniture')}>Furniture <ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Furniture' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Women's Fashion</h4>
                      <ul>
                        <li><a href="#">Sarees</a></li>
                        <li><a href="#">Sandals</a></li>
                        <li><a href="#">Watchs</a></li>
                        <li><a href="#">Shoes</a></li>
                      </ul>
                      <h4 className="title">Furniture</h4>
                      <ul>
                        <li><a href="#">Chairs</a></li>
                        <li><a href="#">Tables</a></li>
                        <li><a href="#">Doors</a></li>
                        <li><a href="#">Bed</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Home, Kitchen</h4>
                      <ul>
                        <li><a href="#">Kettle</a></li>
                        <li><a href="#">Toaster</a></li>
                        <li><a href="#">Dishwasher</a></li>
                        <li><a href="#">Microwave oven</a></li>
                        <li><a href="#">Pitcher</a></li>
                        <li><a href="#">Blender</a></li>
                        <li><a href="#">Colander</a></li>
                        <li><a href="#">Tureen</a></li>
                        <li><a href="#">Cookware</a></li>
                      </ul>
                    </div>
                    <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div>
                  </div>
                </li>
                <li className={`menu-item-has-children ${subMenuActive === 'Electronic' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Electronic')}>Electronic<ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Electronic' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Women's Fashion</h4>
                      <ul>
                        <li><a href="#">Sarees</a></li>
                        <li><a href="#">Sandals</a></li>
                        <li><a href="#">Watchs</a></li>
                        <li><a href="#">Shoes</a></li>
                      </ul>
                      <h4 className="title">Furniture</h4>
                      <ul>
                        <li><a href="#">Chairs</a></li>
                        <li><a href="#">Tables</a></li>
                        <li><a href="#">Doors</a></li>
                        <li><a href="#">Bed</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Home, Kitchen</h4>
                      <ul>
                        <li><a href="#">Kettle</a></li>
                        <li><a href="#">Toaster</a></li>
                        <li><a href="#">Dishwasher</a></li>
                        <li><a href="#">Microwave oven</a></li>
                        <li><a href="#">Pitcher</a></li>
                        <li><a href="#">Blender</a></li>
                        <li><a href="#">Colander</a></li>
                        <li><a href="#">Tureen</a></li>
                        <li><a href="#">Cookware</a></li>
                      </ul>
                    </div>
                    <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div>
                  </div>
                </li>
                <li className={`menu-item-has-children ${subMenuActive === 'Appliances' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Appliances')}>Appliances <ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Appliances' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Women's Fashion</h4>
                      <ul>
                        <li><a href="#">Sarees</a></li>
                        <li><a href="#">Sandals</a></li>
                        <li><a href="#">Watchs</a></li>
                        <li><a href="#">Shoes</a></li>
                      </ul>
                      <h4 className="title">Furniture</h4>
                      <ul>
                        <li><a href="#">Chairs</a></li>
                        <li><a href="#">Tables</a></li>
                        <li><a href="#">Doors</a></li>
                        <li><a href="#">Bed</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Home, Kitchen</h4>
                      <ul>
                        <li><a href="#">Kettle</a></li>
                        <li><a href="#">Toaster</a></li>
                        <li><a href="#">Dishwasher</a></li>
                        <li><a href="#">Microwave oven</a></li>
                        <li><a href="#">Pitcher</a></li>
                        <li><a href="#">Blender</a></li>
                        <li><a href="#">Colander</a></li>
                        <li><a href="#">Tureen</a></li>
                        <li><a href="#">Cookware</a></li>
                      </ul>
                    </div>
                    <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div>
                  </div>
                </li>
                <li className={`menu-item-has-children ${subMenuActive === 'Beauty & Toys' ? 'active' : ''}`}>
                  <a onClick={() => showSubMenu('Beauty & Toys')}>Beauty & Toys<ChevronDown fontSize={1} /></a>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${subMenuActive === 'Beauty & Toys' ? 'active' : ''}`}>
                    <div className="list-item">
                      <h4 className="title">Men's Fashion</h4>
                      <ul>
                        <li><a href="#">T-Shirts</a></li>
                        <li><a href="#">Jeans</a></li>
                        <li><a href="#">Suit</a></li>
                        <li><a href="#">Kurta</a></li>
                        <li><a href="#">Watch</a></li>
                      </ul>
                      <h4 className="title">Beauty</h4>
                      <ul>
                        <li><a href="#">Moisturizer</a></li>
                        <li><a href="#">Face powder</a></li>
                        <li><a href="#">Lipstick</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Women's Fashion</h4>
                      <ul>
                        <li><a href="#">Sarees</a></li>
                        <li><a href="#">Sandals</a></li>
                        <li><a href="#">Watchs</a></li>
                        <li><a href="#">Shoes</a></li>
                      </ul>
                      <h4 className="title">Furniture</h4>
                      <ul>
                        <li><a href="#">Chairs</a></li>
                        <li><a href="#">Tables</a></li>
                        <li><a href="#">Doors</a></li>
                        <li><a href="#">Bed</a></li>
                      </ul>
                    </div>
                    <div className="list-item">
                      <h4 className="title">Home, Kitchen</h4>
                      <ul>
                        <li><a href="#">Kettle</a></li>
                        <li><a href="#">Toaster</a></li>
                        <li><a href="#">Dishwasher</a></li>
                        <li><a href="#">Microwave oven</a></li>
                        <li><a href="#">Pitcher</a></li>
                        <li><a href="#">Blender</a></li>
                        <li><a href="#">Colander</a></li>
                        <li><a href="#">Tureen</a></li>
                        <li><a href="#">Cookware</a></li>
                      </ul>
                    </div>
                    <div className="list-item"><img src="https://images.unsplash.com/photo-1549497538-303791108f95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80" alt="Chair" />
                    </div>
                  </div>
                </li> */}
                {/* <li><a href="#">Grocery</a>
                </li>
                <li><a href="#">Todays Deal</a>
                </li> */}
                {/* <li className="header-bg">
                  <a className="d-flex align-items-center text-dark px-3 h-100"><span className="mx-2">
                    <ShoppingBag className='iconFont' />
                  </span><span className="d-none d-xl-block mx-2 fs-14 fw-700 text-black">₹
                    0.00</span><span className="nav-box-text d-none d-xl-block ml-2 text-black fs-12">
                      ( <span className="cart-count">0 </span> Items )
                    </span>
                  </a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section >

  )
}

export default ProductListCateogry