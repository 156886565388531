

// export const IN_URL = "https://portal.indiazona.in/api/v2";
// export const IN_URL = "https://indiazonaportal.addonwebtech.com/api/v2";
export const IN_URL = "https://portal.indiazona.in/api/v2";

// export const SELLER_LOGIN = "https://indiazonaportal.addonwebtech.com/sell/er/login"
// export const SELLER_ADD = "https://indiazonaportal.addonwebtech.com/shopAdd"

export const SELLER_LOGIN = "https://portal.indiazona.in/seller/login";
export const SELLER_ADD = "https://portal.indiazona.in/shopAdd";

export const DEALER = "https://portal.indiazona.in/dealers/create";
// export const DEALER = "https://indiazonaportal.addonwebtech.com/dealers/create";

export const SYS_KEY = "a6e1f053-4e0d-4fd6-9687-08de845794e0";