import React from 'react'
import { Link } from 'react-router-dom'
import { DEALER, SELLER_ADD } from '../../BaseUrl'

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="container-fluid">
                <div className="footer_border_line">
                    <div className="row">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-2">
                            <div className="footer__inner">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12  col-lg-12 col-md-12 col-sm-12">
                                        <h6><span>Contact Us</span></h6>
                                    </div>
                                </div>
                                <ul className="footer__ul">
                                    <li className="footer_li flex gap-2">
                                        <div className="footer_icon">
                                            <img src="/assets/images/svg-img/chat.svg" alt="chat-icon" />
                                        </div>
                                        <div className="footer_contect">
                                            <p><a href='tel: +918374320725'><span>+91 8374320725</span></a></p>
                                            <p>care@indiazona.com</p>
                                        </div>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <div className="footer_icon">
                                            <img src="/assets/images/svg-img/location.svg" alt="location-icon" />
                                        </div>
                                        <div className="footer_contect">
                                            <a href="https://maps.app.goo.gl/cwre5gT9Ho1Nyygo9" target="_blank" rel="noopener noreferrer">
                                                <p>11, Clive Row, 4th Floor, </p>
                                                <p>Kolkata, India - 700001</p>
                                            </a>
                                        </div>
                                    </li>

                                    <li className="footer_li flex gap-2">
                                        <div className="footer_icon">
                                            <img src="/assets/images/svg-img/clock.svg" alt="clock-icon" />
                                        </div>
                                        <div className="footer_contect">
                                            <p>Monday to Saturday</p>
                                            <p>09:00 AM to 06:00 PM</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 mb-2">
                            <div className="footer__inner">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12  col-lg-12 col-md-12 col-sm-12">
                                        <h6><span>Indiazona Marketplace</span></h6>
                                    </div>
                                </div>
                                <ul className="footer__ul">
                                    <li className="footer_li flex gap-2">
                                        <Link to="/">Homepage</Link>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <Link to="/About-us">About Us</Link>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href={DEALER}
                                            target='_blank' rel="noopener noreferrer">Become a Partner</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href={SELLER_ADD} target='_blank' rel="noopener noreferrer">Become a Seller</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <Link to="/">Our Testimonials</Link>
                                    </li>
                                    {/* <li className="footer_li flex gap-2">
                                        <Link to="#">Our Blogs</Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 mb-2">
                            <div className="footer__inner">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12  col-lg-12 col-md-12 col-sm-12">
                                        <h6><span>Customer Support</span></h6>
                                    </div>
                                </div>
                                <ul className="footer__ul">
                                    <li className="footer_li flex gap-2">
                                        <Link to="/">My Account</Link>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <Link to="/">My Orders</Link>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="/wishlist">My Wishlist</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="/cart">My Cart</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="#">Track Order</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <Link to="/customer-faqs">FAQs</Link>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <Link to="/contect-us">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 mb-2">
                            <div className="footer__inner">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12  col-lg-12 col-md-12 col-sm-12">
                                        <h6><span>Indiazona Offerings</span></h6>
                                    </div>
                                </div>
                                <ul className="footer__ul">
                                    <li className="footer_li flex gap-2">
                                        <a href="#">All Product Categories</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="#">Swipe & Buy!</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="#">Made in India Products</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="#">Handmade Products</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="#">Sustainable Products</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="#">Brands</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="#">Top Deals</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
                            <div className="footer__inner">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12  col-lg-12 col-md-12 col-sm-12">
                                        <h6><span>Policies at Indiazona</span></h6>
                                    </div>
                                </div>
                                <ul className="footer__ul">
                                    {/* <li className="footer_li flex gap-2">
                                        <a href="https://portal.indiazona.in/dealers/create"
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            className="fs-13 fw-700 text-secondary-base ml-2">
                                            <span>Become A Partner</span>
                                            <span style={{ color: 'green', fontWeight: "bold" }}> Apply Now</span>
                                        </a>
                                    </li> */}
                                    <li className="footer_li flex gap-2">
                                        <Link to="/cancel-policy">Cancellation Policy</Link>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <Link to="/return-policy">Return & Exchange Policy</Link>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <Link to="/compliance">Compliance</Link>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="https://portal.indiazona.in/terms">Terms Of Use</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="https://portal.indiazona.in/privacy-policy">Privacy Policy</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <a href="#">Sitemap</a>
                                    </li>
                                    <li className="footer_li flex gap-2">
                                        <Link to="/vendor-faqs">Vendor FAQs
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="footer__copyright">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                                <div className="copyright">
                                    <p>Copyright © 2024. <span><a href="https://indiazona.in">IndiaZona.</a></span> All
                                        rights reserved.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="copyright text-center">
                                    <img src="/assets/images/payment.png" alt="payment_img " />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                                <div className="copyright text-end">
                                    <a href="https://portal.indiazona.in/privacy-policy">Privacy Policy </a>
                                    <span className="text-white"> / </span>
                                    <a href="https://portal.indiazona.in/terms">Terms &amp; Conditions</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer