import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LocalstorageService from '../../../helpers/localstorage-services';
import { getCartData } from '../../../middlewares/cart/cart';
import { fetchAddress } from '../../../middlewares/UserProfile/address';
import { addAddressReset, primaryAddressReset } from '../../../slices/addressSlice';

const CheckOutSummary = () => {
    const dispatch = useDispatch();
    const { cart, cartLoading, cartMessage, cartError } = useSelector((state) => state.cart);
    const { SuccessMessage, userAddress, } = useSelector((state) => state.address);
    const userData = LocalstorageService.getLoggedInUserDetails();
    useEffect(() => {
        if (SuccessMessage) {
            const delivery_charge = userAddress.find(address => address?.set_default === 1)
            const bodyFormData = new FormData();
            bodyFormData.append("user_id", userData?.id);
            bodyFormData.append("customer_pincode", delivery_charge);
            bodyFormData.append("is_checkout", 1);
            dispatch(getCartData(bodyFormData));
        }
    }, [SuccessMessage, cart]);
    useEffect(() => {
        if (SuccessMessage) {
            dispatch(primaryAddressReset());
        }
    }, [SuccessMessage, cart]);

    return (
        <div className="col-12 col-md-4 my-5">
            <div className="total-content">
                <div className="title text-center">
                    <h5>Order Summary</h5>
                </div>
                <div className="order-details my-3">
                    <div className="bill-section">
                        <p>Sub Total</p>
                        <p>₹{cart?.sub_total}</p>
                    </div>
                    <div className="bill-section">
                        <p>Delivery Charges</p>
                        <p>FREE</p>
                    </div>
                    <div className="bill-section">
                        <p>Tax</p>
                        <p><span>+</span>  ₹{cart?.tax}</p>
                    </div>


                    <div className="bill-section mt-3">
                        <p><b>Amount Payable</b></p>
                        <p><b>₹{cart?.total_amount}</b></p>
                    </div>
                </div>
                <div className='table-responsive'>

                </div>
            </div>
        </div>
    )
}

export default CheckOutSummary