import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Widget, addResponseMessage, addUserMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import LocalstorageService from '../helpers/localstorage-services';
import { chatRecieverList, getChatMessage, sendChatMessage } from '../middlewares/chat/chat';

const ChatWidget = () => {
  const dispatch = useDispatch();
  const { chatReciever, getchat } = useSelector(state => state.chat);
  const user = LocalstorageService.getLoggedInUserDetails();
  const previousMessagesRef = useRef([]);
  console.log('1111111111')
  const [sendingMessage, setSendingMessage] = useState(false);
  useEffect(() => {
    if (user?.id) {
      dispatch(chatRecieverList());
      dispatch(getChatMessage());
    }
  }, [dispatch, user?.id]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (user?.id) {
        dispatch(getChatMessage());
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [dispatch, user]);

  useEffect(() => {
    if (getchat && getchat.length) {
      getchat.forEach(message => {
        if (!previousMessagesRef.current.includes(message.id)) {
          previousMessagesRef.current.push(message.id);

          if (message.sender_id === user.id) {
            addUserMessage(message.message);
          } else {
            addResponseMessage(message.message);
          }
        }
      });
    }
  }, [getchat, user]);

  const handleNewUserMessage = (newMessage) => {
    if (!newMessage.trim() || sendingMessage) return;

    setSendingMessage(true);

    const bodyFormData = new FormData();
    bodyFormData.append("message", newMessage);
    bodyFormData.append("receiver_id", chatReciever?.id);

    dispatch(sendChatMessage(bodyFormData))
      .then((messageData) => {
        previousMessagesRef.current.push(messageData.id);
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      })
      .finally(() => {
        setSendingMessage(false);
      });
  };

  if (!user?.id) return null;

  return (
    <div className="App">
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title="My Chat Widget"
        subtitle="How can we help you?"
        senderPlaceHolder="Type your message..."
        toggleWidget={true}
      />
    </div>
  );
};

export default ChatWidget;
