import React, { useEffect, useState } from 'react';
import SideBarManu from './SideBarManu';
import Footer from '../../Components/Common/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderData } from '../../middlewares/cart/order';
import { toast } from 'react-toastify';
import OrderPagination from '../../helpers/OrderPagination';
import Skeleton from 'react-loading-skeleton';

const OrdersPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const IsLogedin = localStorage.getItem('IN_USER_DATA');
    const { getOrderHistory, getOrderHistoryLoading } = useSelector((state) => state?.order);

    useEffect(() => {
        if (IsLogedin) {
            navigate('/user-orders')
        } else {
            navigate('/')
            toast.error('Please log in again to continue');
        }
    }, [IsLogedin]);

    useEffect(() => {
        dispatch(getOrderData());
    }, []);

    const handlePagination = (e) => {
        const formData = new FormData();
        formData.append("page", e);
        dispatch(getOrderData(formData));
    }

    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Personal Information</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><a href="#">Home</a></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Orders Page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="profile-section profile-wrapper">
                <div className="container">
                    <div className="row">
                        <SideBarManu />
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 account-wrapper">
                            <div className="account-card">
                                <div className="table-responsive table-style-1">
                                    {
                                        getOrderHistoryLoading ? (
                                            <table className="table table-hover mb-3">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Order #</th>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                        <th>Amount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.from({ length: 4 }).map((_, index) => (
                                                        <tr key={index}>
                                                            <td><Skeleton width={50} height={50} /></td>
                                                            <td><Skeleton width={100} /></td>
                                                            <td><Skeleton width={80} /></td>
                                                            <td><Skeleton width={80} /></td>
                                                            <td><Skeleton width={80} /></td>
                                                            <td><Skeleton width={50} /></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : <table className="table table-hover mb-3">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Order #</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getOrderHistory?.data?.map((elem, i) =>
                                                    <tr key={i}>
                                                        <td><img src={elem?.order_detail[0]?.photos[0]?.path} style={{ width: '50px', height: '50px', }} /></td>
                                                        <td><span className="fw-medium">{elem.code}</span></td>
                                                        <td>{elem.date}</td>
                                                        <td>{elem.delivery_status}</td>
                                                        <td><span className="badge bg-info m-0">{elem.grand_total}</span></td>
                                                        <td className="btn-link text-underline p-0" onClick={() => navigate('/order-details', { state: {Id : elem?.id} })} style={{ cursor: 'pointer' }}>View</td>
                                                    </tr>
                                                )}
                                                {getOrderHistory?.length === 0 && (
                                                    <tr>
                                                        <td colSpan="6" style={{ textAlign: 'center' }}>No Order History Found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    }

                                </div>
                                <div className="row justify-content-center d-flex order-pagination">
                                    <div className="col-12">
                                        {getOrderHistory?.metaData?.total >= 1 && (
                                            <OrderPagination
                                                prev={() => {
                                                    handlePagination(parseInt(getOrderHistory?.metaData?.current_page) - 1);
                                                }}
                                                next={() => {
                                                    handlePagination(parseInt(getOrderHistory?.metaData?.current_page) + 1);
                                                }}
                                                prevDisabled={parseInt(getOrderHistory?.metaData?.current_page) === 1}
                                                nextDisabled={parseInt(getOrderHistory?.metaData?.current_page) === getOrderHistory?.metaData?.total}
                                                currentPage={parseInt(getOrderHistory?.metaData?.current_page)}
                                                totalPages={getOrderHistory?.metaData?.total}
                                                handlePageClick={(page) => handlePagination(page)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OrdersPage;
