import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slices/auth.slice";
import colorSlice from "../slices/color.slice";
import categoriesSlice from "../slices/categories.slice";
import shortVideoSlice from "../slices/shortVideo.slice";
import productSlice from "../slices/products.slice";
import sidebarFilterSlice from "../slices/sidebarFilter.slice";
import cartSlice from "../slices/cart";
import wishlistSlice from "../slices/wishlist.slice";
import addressSlice from "../slices/addressSlice";
import doodleSlice from "../slices/doodleSlice";
import searchSlice from "../slices/search.slice";
import ProfileSlice from "../slices/ProfileSlice";
import orderSlice from "../slices/orderSlice";
import mobileVerifySlice from "../slices/resendOtp";
import estimatedorderSlice from "../slices/estimatedOrder";
import accountSlice from "../slices/accountSlice";
import returnRequestSlice from "../slices/returnRequest";
import chatSlice from "../slices/chatSlice";


function configStore() {
  const currentEnv = "development";
  // const currentEnv = process.env.REACT_APP_ENV || ENV_DEVELOPMENT;
  const store = configureStore({
    reducer: {
      auth: authSlice,
      color: colorSlice,
      category: categoriesSlice,
      shortvideo: shortVideoSlice,
      product: productSlice,
      sidebarFilter: sidebarFilterSlice,
      cart: cartSlice,
      wishlist: wishlistSlice,
      address: addressSlice,
      doodle: doodleSlice,
      search: searchSlice,
      profile: ProfileSlice,
      order: orderSlice,
      singleOrder: orderSlice,
      otpVerification: mobileVerifySlice,
      estimated: estimatedorderSlice,
      accountDetails: accountSlice,
      returnRequest: returnRequestSlice,
      chat: chatSlice
    },

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
    devTools: currentEnv,
    // devTools: currentEnv !== ENV_PRODUCTION,
  });
  return store;
}

export default configStore;
